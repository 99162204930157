<template>
  <v-app class="app-warp">
    <img class="logo" src="@/assets/login/logo_bai.png" alt="" @click="$router.push('/h5Number/Home')">
    <v-banner
      single-line
      height="602"
      class="banner"
    >
      <img src="https://h5.ophyer.cn/official_website/page/aigc/h5-banner-01.png" alt="">
      <div class="text">
        <p>AIGC</p>
        <p>探索AI边界，提升AI技术水平 <br>发展大算力，支撑元宇宙建设及应用</p>
      </div>
    </v-banner>

    <!-- 核心技术 -->
    <v-card class="card-warp warp1" :flat="true"> 
      <div class="public-titles">
        <div><span></span><span></span><span></span><span></span></div>
        <p class="tit-z">核心技术</p>
        <div><span></span><span></span><span></span><span></span></div>
      </div>
      <div class="con">
        <ul class="video-text">
          <li v-for="(item,index) in warp2Content.list" :key="index" :class="warp2Content.active === index ? 'active' : ''">
            <video 
              :ref="'video'+index"
              :src="item.video"
              loop="loop"
              muted="true"
              preload="auto"
              x5-video-player-type="h5"
              x5-video-player-fullscreen="true"
              webkit-playsinline="true"
              x-webkit-airplay="true"
              playsinline="true"
            ></video>
            <div class="text">
              <p>{{ item.title }}</p>
              <p>{{ item.text }}</p>
            </div>
          </li>
        </ul>
        <ul class="controllo-box">
          <li v-for="(item,index) in warp2Content.list" :key="index" @click="hanldeClick(index)" :class="warp2Content.active === index ? 'active' : ''">
            <span class="progress-bar"><span :ref="'progressBar'+index"></span></span>
          </li>
        </ul>
      </div>
    </v-card>

    <!-- 应用场景 -->
    <v-card class="card-warp warp2" :flat="true">
      <div class="public-titles">
        <div><span></span><span></span><span></span><span></span></div>
        <p class="tit-z">应用场景</p>
        <div><span></span><span></span><span></span><span></span></div>
      </div>
      <div class="swiper-box">
        <swiper ref="mySwiper2" :options="swiperOptionOne">
          <swiper-slide v-for="(v,k) in courseProducts" :key="k">
            <img :src="v.img" alt="">
            <div :class="['conbot', 'conbot'+(k+1)]">
              <p class="tit">{{ v.sub }}</p>
              <p class="text">{{ v.text }}</p>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </v-card>

    <H5OfficialWebsiteFooter :color="'balck'" />
  </v-app>
</template>

<script>

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: 'DigitalMan',
  components: {
    Swiper,
    SwiperSlide,
    H5OfficialWebsiteFooter: () => import('@/components/common/h5OfficialWebsiteFooter.vue'),

  },
  data() {
    return {

      warp2Content: {
        active: 0,
        list: [
          {
            id: 1,
            video: 'https://h5.ophyer.cn/official_website/page/aigc/warp2Content-video-01-2.mov',
            title: '图像处理',
            text: '结合图像处理技术，生成符合要求的图片。对于照片风格处理和图像增强，利用其深度学习算法，快速处理照片，达到美化和增强的目的'
          },
          {
            id: 2,
            video: 'https://h5.ophyer.cn/official_website/page/aigc/warp2Content-video-02-2.mov',
            title: '视频处理',
            text: '利用其自然语言处理技术合成语音，生成数字人播报。您可以输入任何需要播报的内容，无论是新闻、广告还是其他文本，都可以快速生成数字人播报，此外结合图像处理技术，将视频转化为漫画风格的图像。'
          },
          {
            id: 3,
            video: 'https://h5.ophyer.cn/official_website/page/aigc/warp2Content-video-03-2.mov',
            title: '3D建模',
            text: '根据输入的文字描述，快速生成3D场景和角色，并且可以通过多张照片，进行小物体3D模型重建，从而快速构建虚拟世界。这种技术可以大大缩短开发时间，提高开发效率。'
          },
          {
            id: 4,
            video: 'https://h5.ophyer.cn/official_website/page/aigc/warp2Content-video-04-2.mov',
            title: '智能客服',
            text: '结合NLP技术实现智能交互，通过声音输入实现文本生成，通过文本输入实现声音生成。这种技术可以广泛应用于智能助手、智能客服等领域，提高智能化水平，提供更好的用户体验。'
          }
        ]
      },
      swiperOptionOne: {
        slidesPerView: "auto",
        centeredSlides:true,
        spaceBetween: -10,
        loop: true,
      },
      courseProducts: [
        {
          img: 'https://h5.ophyer.cn/official_website/page/aigc/courseProducts-01.png',
          sub: '游戏',
          text: '游戏 NPC 人物生成、虚拟场景和关卡生成创作捕捉灵感，创新互动形式，NPC 逻辑和剧情生成，数字资产生成，AI Bot 等'
        },
        {
          img: 'https://h5.ophyer.cn/official_website/page/aigc/courseProducts-02-2.jpg',
          sub: '电商',
          text: '数字人直播带货，VR/AR 商品展示商品 3D 模型、虚拟主播，虚拟货场构建，通过和 AR、VR等新技术的结合，实现视听等多感官交互的沉浸式购物体验'
        },
        {
          img: 'https://h5.ophyer.cn/official_website/page/aigc/courseProducts-03.png',
          sub: '媒体与影视',
          text: 'AI助力视频剧本创作，打造虚拟偶像 IP影视剧本创作灵感激发，角色和场景创作空间扩展，影视后期制作视频剪辑，拓展创作空间，提升作品质量；娱乐趣味性图像或音视频生成、打造虚拟偶像、开发C端用户数字化身等'
        },
        {
          img: 'https://h5.ophyer.cn/official_website/page/aigc/courseProducts-04-2.png',
          sub: '广告与传媒 ',
          text: '创意和素材生成，AI 文字辅助创作，AI 绘画写稿机器人、采访助手、视频字幕生成、语音播报、视频锦集、人工智能合成主播等，人机协同生产，推动媒体融合'
        }
      ],
    }
  },
  created() {
    setTimeout(()=>{
      this.hanldeClick(0)
    },2000)
  },
  methods: {
    hanldeClick(index) {
      // if(index === this.warp2Content.active) return
      if(!this.$refs['progressBar'+this.warp2Content.active][0]) return
      this.$refs['progressBar'+this.warp2Content.active][0].style.width = '0%'
      clearInterval(this.setTime)
      this.warp2Content.active = index
      let dom = this.$refs['video'+this.warp2Content.active][0]
      if(!dom) return
      dom.currentTime = 0
      dom.play()
      this.timeProgressBar(this.warp2Content.active, parseInt(dom.duration) * 1000)
    },
    
    timeProgressBar(index, time) {
      this.$refs['progressBar'+index][0].style.width = '0%'
      let times = time / 1000
      let tc = time / 1000
      let w = 0
      this.setTime = setInterval(() => {
        tc += times
        w += time / 10000 / times
        try {
          this.$refs['progressBar'+index][0].style.width = w+'%'
          if(tc >= time) {
            clearInterval(this.setTime)
            if(index === 3) {
              this.hanldeClick(0)
            } else {
              this.hanldeClick(index+1)
            }
          }
        } catch (e) {
          clearInterval(this.setTime)
        }
      }, times)
    },
  },

}
</script>

<style lang="scss" scoped>
.app-warp {
  margin: 0 auto;
  text-align: center;
  background: #090B10;
}

.logo {
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: 15px;
  width: 120px !important;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}

.v-banner {
  position: relative;
  ::v-deep .v-banner__text {
    background: #090B10;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .text {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
    padding-top: 120px;
    p:nth-of-type(1) {
      height: 60px;
      font-size: 60px;
      font-family: Arial;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 60px;
    }
    p:nth-of-type(2) {
      font-size: 18px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-family: Arial;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 26px;
    }
  }
}


.public-titles {
  height: 40px;
  margin: 0px auto 0px;
  position: relative;
  text-align: center;
  padding-top: 0;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .tit-z {
    height: 35px;
    font-size: 24px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 35px;
    padding: 0 13px;

  }
  div {
    display: inline;
    display: flex;
    align-items: center;
    span:nth-of-type(1) {
      width: 54px;
      height: 1px;
      background: #BDC0CD;
      border-radius: 1px 1px 1px 1px;
    }
    span:nth-of-type(2) {
      width: 5px;
      height: 5px;
      background: #BDC0CD;
      border-radius: 7px;
      margin-right: 7px;
    }
    span:nth-of-type(3) {
      width: 3px;
      height: 3px;
      background: #BDC0CD;
      border-radius: 5px;
      margin-right: 7px;
    }
    span:nth-of-type(4) {
      width: 2px;
      height: 2px;
      background: #BDC0CD;
      border-radius: 3px;
    }
    &:nth-of-type(2) {
      transform: rotateZ(180deg);
    }
  }
  p {
    margin-bottom: 0;
  }

}

.card-warp {
  padding: 40px 20px;
  background: #0B0C12;
}

.warp1 {
  .video-text {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    padding: 0;
    li {
      width: 100%;
      height: 100%;
      display: none;
    }
    video {
      width: 100%;
      height: 210px;
      object-fit: cover;
      border-radius: 10px;
    }
    .text {
      margin-top: 20px;
      width: 100%;
      padding: 0 15px;
      p:nth-of-type(1) {
        height: 23px;
        font-size: 16px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN, Arial;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 23px;
      }
      p:nth-of-type(2) {
        margin-top: 10px;
        font-size: 13px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN, Arial;
        font-weight: 400;
        color: rgba(255,255,255,0.7);
        line-height: 19px;
      }
      p {
        text-align: center;
        margin-bottom: 0;
      }
    }
    .active {
      display: block;
    }
  }
  .controllo-box {
    height: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 0;
    li {
      width: 24%;
      position: relative;
      cursor: pointer;
      .progress-bar {
        position: absolute;
        z-index: 9;
        bottom: 0;
        width: 100%;
        display: block;
        height: 2px;
        background: #43444D;
        transform-origin: 0;
        span {
          display: block;
          height: 2px;
          width: 0%;
          background: #fff;
        }
      }
    }
    .active {
      font-size: 14px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN, Arial;
      font-weight: 700;
      color: #FFFFFF;
      line-height: 20px;
    }
  }
  
}


.warp2 {
  padding: 40px 0 30px !important;
  background: #17181E;
  .swiper-box {
    .swiper-wrapper {
      .swiper-slide {
        width: 80% !important;
        margin: 0 auto;
        position: relative;
        box-sizing: border-box;
        background: #17181E;
        img {
          text-align: center;
          margin: 0 auto;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          border-radius: 10px;
          // height: 270px;
          object-fit: cover;
        }
        .conbot {
          width: 100%;
          height: 100%;
          position: absolute;
          // top: 0;
          bottom: 0.5%;
          left: 0;
          background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.3) 55%, rgba(0,0,0,0.7) 100%);
          color: #fff;
          padding: 20px;
          border-radius: 10px;
          display: flex;
          flex-wrap: wrap;
          align-items: flex-end;
          align-content: flex-end;
          box-sizing: border-box;
          margin: 0;
          .tit {
            font-size: 24px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN, Arial;
            font-weight: 700;
            color: #FFFFFF;
            padding: 0;
            margin-bottom: 15px;
          }
          .text {
            font-size: 12px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN, Arial;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 17px;
            text-align: start;
            padding: 0;
            margin: 0;
          }
        }
        .conbot3 {
          background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 55%, rgba(0,0,0,0.8) 100%);
        }
      }
      .swiper-slide-prev{
        transform: scale(0.80);
        opacity: 0.8;
      }
      .swiper-slide-next{
        transform: scale(0.80);
        opacity: 0.8;
      }
    }
  }
}

</style>
